.cart {
  background-color: #fafafa;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.cart-bars {
  margin-left: 1.8rem;
  font-size: 1.5rem;
}

.cart-menu {
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: -400px;
  transition: right 250ms ease-in-out;
  display: flex;
  align-items: center;
  z-index: 1002;
  /* background-color: rgba(245, 236, 231, 0.95); */
  background-color: #fafafa;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
}

@media (max-width: 768px) {
  .cart-menu {
    width: 100%;
    right: -100%;
    justify-content: center;
  }
}


.cart-menu.active {
  right: 0;
}

.cart-text {
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 16px;
  height: 60px;
}

.cart-text a {
  color: #fafafa;
  font-size: 18px;
  width: 95%;
  display: flex;
  align-items: center;
  padding: 0 18px;
}

.cart-text a:hover {
  color: #fc00b9;
  transition: 0.3s ease-in-out;
}

.cart-menu-items {
  width: 100%;
}

.cart-toggle {
  background-color: #fafafa;
  width: 20%;
  height: 0px;
  display: flex;
  position: absolute;
  top: 40px;
  right: 0px;
}

span {
  margin-left: 16px;
}

.cart-title {
  color: #000000;
  text-align: center; /* This centers the text within the .cart-title element */
  font-size: 1.5rem;
  width: 55%;
  height: 0px;
  display: flex;
  position: absolute;
  top: 80px;
  right: 0px;
}

.cart-empty {
  color: #000000;
  text-align: center; /* This centers the text within the .cart-empty element */
  font-size: 1.5rem;
  width: 73%;
  height: 0px;
  display: flex;
  position: absolute;
  top: 50%px;
  right: 0px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(252, 0, 185, 0.7);
  z-index: 1001;
  display: none;
}

.overlay.active {
  display: block;
}