/* create class for background colors */
.App {
  text-align: center;
  /* background-color: #f5ece7; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg-maincolor {
  background-color: #fc00b9;
}
.bg-secondcolor {
  background-color: #fbdaef;
}
.bg-thirdcolor {
  background-color: #EEE5E2;
}
.text-pcolor {
  color: #fc00b9;
}
.text-scolor {
  color: #b48f85;
}
.image-transition {
  transition: opacity 0.3s ease-in-out;
}